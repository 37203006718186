/* assets/styles.css */

/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;300;400&display=swap');

body {
    margin: 0;
    padding: 0;
    background-color: #000000;
    color: #ffffff;
    position: relative;
    overflow-x: hidden;
    font-family: 'Source Code Pro', monospace;
    font-weight: 200;
  }

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 200;
  letter-spacing: -0.02em;
}

/* Code blocks */
pre, code {
  font-family: 'Source Code Pro', monospace !important;
  font-size: 0.9em !important;
  font-weight: 200 !important;
}

pre {
  background-color: #121212 !important;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
  position: relative;
  z-index: 1;
}

code {
  color: #1abc9c;
}

/* Navigation and menu items */
.MuiTypography-root {
  font-family: 'Source Code Pro', monospace;
  font-weight: 200;
}

/* Links */
a {
  color: #1abc9c;
  text-decoration: none;
  font-family: 'Source Code Pro', monospace;
  font-weight: 200;
}

a:hover {
  text-decoration: underline;
  color: #16a085;
}

/* Shine effect */
body::before {
  content: '';
  position: fixed;
  top: 0;
  left: -50%;
  width: 200%;
  height: 100%;
  background: radial-gradient(
    circle at center,
    rgba(26, 188, 156, 0.03) 0%,
    rgba(0, 0, 0, 0) 70%
  );
  pointer-events: none;
  z-index: 0;
}

/* Additional shine elements */
.shine-element {
  position: fixed;
  width: 300px;
  height: 300px;
  background: radial-gradient(
    circle at center,
    rgba(26, 188, 156, 0.02) 0%,
    rgba(0, 0, 0, 0) 70%
  );
  border-radius: 50%;
  pointer-events: none;
  z-index: 0;
}

.shine-element:nth-child(1) {
  top: -150px;
  right: -150px;
}

.shine-element:nth-child(2) {
  bottom: -150px;
  left: -150px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #121212;
}

::-webkit-scrollbar-thumb {
  background: #1abc9c;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #16a085;
}